<template>
  <div class="d-flex justify-content-center align-items-center gap-1" v-b-tooltip.hover :title="'на ' + actualDate">
    <InfoIcon class="help-icon text-muted align-middle" style="height: 24px" />
    <h4 class="m-0" id="tooltip-target-1">
      Долг
      <b> {{ actualDebts }} р. </b>
    </h4>
  </div>
</template>

<script>
import { InfoIcon } from "@zhuowenli/vue-feather-icons";

export default {
  components: {
    InfoIcon,
  },
  props: {
    actualDate: {
      type: String,
      default: "",
    },
    actualDebts: {
      type: String,
      default: "",
    },
  },
};
</script>
