export function blink(thisItem, blinkTimes, refId) {
    const refItem = thisItem.$refs[refId];
    if (!refItem) return;

    const item = refItem.$el || refItem;

    item.classList.add("blink-transition");
    if (blinkTimes == 0) return;
    item.classList.add("blink");
    setTimeout(() => {
        item.classList.remove("blink");
        setTimeout(() => {
            blink(thisItem, blinkTimes - 1, refId);
        }, 500);
    }, 500);
}


const phonePattern = /(^(\+7|7|8)?[\s\\-]?\(?[489][0-9]{2}\)?[\s\\-]?[0-9]{3}[\s\\-]?[0-9]{2}[\s\\-]?[0-9]{2}$)|^$/;
export default phonePattern;

export function phoneCellLink(phone) {
    if (!phone) return null;
    var cleaned = ('' + phone).replace(/\D/g, '');
    var match = cleaned.match(/^(\+7|7|8)?(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
        var intlCode = (!match[1] ? '+7 ' : '');
        return `<a href='tel:+7${phone}'>${[intlCode, '(', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('')}</a>`;
    }
    return null;
}
